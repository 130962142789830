// import React from "react";
// import logo from "../../assets/image/NTG-logo-removebg.png";
// import "./index.css";
// const Header = () => {
//   return (
//     <header className="shadow-2xl">
//       <div className=" xl:max-w-[1280px] md:max-w-[1024px] mx-auto sm:max-w-[576px] lg:px-10 px-2">
//         <div className="lg:flex items-center justify-between py-3 hidden">
//           <div className="flex items-center justify-center">
//             <img
//               style={{ width: "120px" }}
//               className="hidden md:block"
//               src={logo}
//               alt=""
//             />
//           </div>
//           <div className="flex font-semibold">
//             <div className="mr-10 tracking-[.15em] flex items-center">
//               <i className="fi fi-rr-phone-call text-xl leading-[15px] mr-[10px]"></i>
//               <a href="tel:+13129679894">(312) 967-9894</a>
//             </div>
//             <div className="mr-10 tracking-[.15em] flex items-center">
//               <i class="fi fi-rr-envelope text-xl leading-[15px] mr-[10px]"></i>
//               tgdispatchteam@gmail.com{" "}
//             </div>
//             <button className="button">Apply free trial</button>
//           </div>
//         </div>

//         {/* Mobile */}

//         <div className="lg:hidden items-center justify-between py-2 flex">
//           <div className="flex items-center justify-center">
//             <img
//               style={{ width: "50px" }}
//               className="block"
//               src={logo}
//               alt=""
//             />
//           </div>
//           <div className="flex flex-col font-semibold">
//             <div className="flex items-center text-xs mb-1">
//               <i className="fi fi-rr-phone-call "></i>
//               <a href="tel:+13129679894">(312) 967-9894</a>
//             </div>
//             <div className="flex items-center text-xs">
//               <i class="fi fi-rr-envelope "></i>
//               tgdispatchteam@gmail.com{" "}
//             </div>
//             {/* <button className="button">Apply free trial</button> */}
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;

import React from "react";
import logo from "../../assets/image/NTG-logo-removebg.png";
import styles from './styles.module.scss';

 const Header = () => {
  return (
    <header className={styles.header} >
      <div className={styles.header__wrap}>
      <img
              style={{ width: "120px" }}
              src={logo}
              alt=""
            />
      <ul className={styles.header__list}>
       
        <li className={styles.header__list__item}>
          <a href={'/'}>About Us</a>
        </li>
        <li className={styles.header__list__item}>
          <a href={'/'}>Services</a>
        </li>
        <li className={styles.header__list__item}>
          <a href={'/'}>Contact Us</a>
        </li>
      </ul>
      <div className={styles.header__burger}>
        
<a href="tel:+13129679894">+1 (312) 967-9894</a>
      </div>
      </div>
    </header>
  );
};
export  default Header;
