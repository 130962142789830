import styles from './styles.module.scss'
export const Steps = () => {

  return(
    <div className={styles.steps}>
     <div className={styles.steps__wrapper}>
      <h2 className={styles.steps__wrapper__title}>Resolute Logistics Company as the Leading Dispatching Service</h2>
     <div className={styles.steps__wrapper__block}>
        <div className={styles.steps__wrapper__block__count}>1</div>
        <h3 className={styles.steps__wrapper__block__title}> Customized Dispatch for Trucks </h3>
        
        <p className={styles.steps__wrapper__block__text}>
        When you become a NTG Logistics family member,our team takes special care of your paperwork,preferences,goals and choosing the best way to increase your profit.


        </p>
      </div>
      <div className={styles.steps__wrapper__block}>
        <div className={styles.steps__wrapper__block__count}>2</div>
        <h3 className={styles.steps__wrapper__block__title}>  Dedicated Assistance  </h3> 
        <p className={styles.steps__wrapper__block__text}>

        Once you're set up with our company,you'll get your personal dispatcher who's going to plan your routes from A to Z.Our specialist will come to your assistance at any time of the day or night until  the job is done.


        </p>
      </div>
      <div className={styles.steps__wrapper__block}>
        <div className={styles.steps__wrapper__block__count}>3</div>
        <h3 className={styles.steps__wrapper__block__title}>  Best Possible Rates  </h3>
        
        <p className={styles.steps__wrapper__block__text}>
        Our dispatchers book a  load based on your preferable  lane.Negotiating  with brokers,our team will get you the best rates and schedule your trips as far ahead as possible.

        </p>
      </div>
      <div className={styles.steps__wrapper__block}>
        <div className={styles.steps__wrapper__block__count}>4</div>
        <h3 className={styles.steps__wrapper__block__title}>   Set-Up Paperwork   </h3>
        
        <p className={styles.steps__wrapper__block__text}>
          When you confirm that you are happy with our dispatching services, 
          your dispatcher takes care of all the necessary paperwork for you to 
          be focused only on the road.
        </p>
      </div>
      <div className={styles.steps__wrapper__block}>
        <div className={styles.steps__wrapper__block__count}>5</div>
        <h3 className={styles.steps__wrapper__block__title}> End-To-End Support </h3>
        
        <p className={styles.steps__wrapper__block__text}>
        When the load is delivered to the final destination,our accounting team will submit all the paperwork to brokers to get your money as soon is possible.
        </p>
      </div>

     </div>
    </div>
  )
}
