import React from "react";
import video from "../../assets/ntg-background.mp4";
import styles from './styles.module.scss';
const BackgroundVideo = ({ videoSource, children, blur, marginX }) => {
  return (
    <>
      <div className="relative">
        <div className={styles.backgroundVideo__bg}></div>
          <div className={styles.backgroundVideo__bg__contentWrap}>
            <h2 className={styles.backgroundVideo__bg__contentWrap__title}>Truck Dispatch Services</h2>
              <p className={styles.backgroundVideo__bg__contentWrap__subTitle}>
              We provide high-quality dispatch service for fleet owners in the USA
              </p>
           </div>
        <video
          autoPlay="autoplay"
          loop="loop"
          muted
          id="video-id"
          className="video w-[100%] mx-auto opacity-[0.99] radius-[10px]"
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={styles.backgroundVideo__swipe}>
            <div className="arrow-down  hidden md:block ">
              <span></span>
              <span></span>
              <span></span>
            </div>
        </div>
      </div>
    </>
  );
};

export default BackgroundVideo;
