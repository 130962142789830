import "./App.css";
import About from "./components/About";
import BackgroundVideo from "./components/BackgroundVideo";
import Gross from "./components/Gross";
import Header from "./components/Header";
import { Faq } from './components/NTG/Faq';
import { Footer } from './components/NTG/Footer';
import Prices from './components/NTG/Prices';
import { Service } from './components/NTG/Service';
import { Steps } from './components/NTG/Steps';
import Services from "./components/Services";
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <main>
      <Header />
        <BackgroundVideo blur={2}/>
        <About/>
        <Service/>
        <Prices/>
        <Steps/>
        {/* <Services/> */}
        <Faq/>
        {/* <Gross/> */}
        <Analytics />
      <Footer/>
    </main>
  );
}

export default App;
