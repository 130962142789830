import { useState } from 'react';

import styles from './styles.module.scss';
import Accordion from '../Accordion';

export const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const accordionData = [
    {
      title: 'How Can the Dispatch Company Help Me to Get Loads?',
       content: 'Our dispatching services include finding loads for fleet owners and owner operators, 24/7 support and handling all paperwork. We work for your loads and allow you to stay focused on driving. Just get in touch with us.',
    },
    { 
      title: ' How much does a Dispatch service cost? ',
       content: 'We have a simple pricing system that depends on number of trucks that you owned.       1-3 Trucks 5% from total gross       4-10 Trucks 4% from total gross       11+ Trucks 3% from total gross'},
    { 
      title: ' What is the best dispatch service for owner Operators? ', 
      content: 'NTG Logistics provides 24/7 dispatching service with pricing starting from 5%  from total gross.Our truck dispatchers always try to find the best loads for owner operators,so NTG Logistics is the best dispatch company for you',
    },
    { 
      title: 'Should I Look For a Truck Dispatch Company Near Me?', 
      content: 'No, you should not. We provide all our truck dispatching services remotely, we will solve all your questions by phone and email, you do not need anything else',
    },
    { 
      title: 'We have the following procedure for interacting with our clients', 
      content: '',
      list: [
          '1 MC AUTHORITY: A copy of your Motor Carrier Authority',
          '2 CERTIFICATE OF INSURANCE It must reflect $1,000,000 Liability and $100,00 Cargo or more',
          '3 W-9 FORM: A copy of your W-9 or IRS assigned EIN number',
          '4 Active  account on Central Dispatch/Super Dispatch'
      ]
    }
  ];

  return(
    <div>
      <div className={styles.faq__wrapper}>
        <h2 className={styles.faq__wrapper__title}>FAQs about Trucking Dispatch</h2>
        <p className={styles.faq__wrapper__subTitle}>Before you start working with Resolute Logistics, you may be interested
           in some questions about truck dispatch, we have collected the most popular 
           ones here:
        </p>
       <div className={styles.faq__wrapper__acc}>
       {accordionData.map((item, index) => (
        <Accordion
          key={index}
          index={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={handleAccordionClick}
          list={item.list}
        />
      ))}
       </div>
       <div>
       </div>
      </div>
    </div>
  )
}
