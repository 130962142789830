import styles from './styles.module.scss';


 const Accordion = ({ 
  index, title, content, isOpen, onClick, list,

}) => {

  const handleClick = () => {
    onClick(index);
  };

  return(
    <div className={ 
      isOpen
      ? styles.accordion__open
      : styles.accordion
      }
      >
      <div className={styles.accordion__header} onClick={handleClick}>
        <h3>{title}</h3>
        <span className={
          isOpen
           ? styles.arrow__up
           : styles.arrow__down
           }>&#9650;</span>
      </div>
      <div className={
         isOpen
         ? `${styles.accordion__action__open} ${styles.accordion__content}`
         : styles.accordion__action
      }>
        {content
         ? content 
         : list.map(item => 
          <p>{item}</p>
          )
         
         }
        </div>
    </div>
    
  )
}
export default Accordion;
