
import styles from './style.module.scss';

import carOpacity from '../../../assets/image/car.jpg'
import car2Opacity from "../../../assets/image/car2.jpg";


const Prices = () => {

  return(
    <div className={styles.prices}>
     <div className={styles.prices__wrapper}>
     {/* <div className={styles.prices__wrapper__bg__left}>
        <img src={carOpacity} alt="" />
      </div> */}
      <div className={styles.prices__wrapper__bg__right}>
        <img src={car2Opacity} alt="" />
      </div>
      <h2 className={styles.prices__wrapper__title}>
        Prices for the Truck Dispatch Service 
        </h2>
        <div className={styles.prices__content}>
          <h4 className={styles.prices__content__title}>
            Your Authority
          </h4>
          <ul className={styles.prices__content__list}>
              <li className={styles.prices__content__list__item__first}>
                Price for our truck dispatch services starts from 5% gross 
                revenue and depending on your trucks total number quantity:
              </li>
              <li className={styles.prices__content__list__item}>1-3 Trucks 5% from total gross</li>
              <li className={styles.prices__content__list__item}>4-10 Trucks 4% from total gross</li>
              <li className={styles.prices__content__list__item}>11+ Trucks 3% from total gross</li>
          </ul>
        </div>
     </div>
    </div>
  )

} 
export default Prices;
