
import styles from './styles.module.scss';
import background from '../../../assets/image/usa.JPG'

export const Service = () => {

  return(
    <section
     className={styles.service}
     style={{ backgroundImage: `url(${background})` }}
     
     >
      <div className={styles.service__bg}></div>
    <div className={styles.service__wrapper}>

      <div className={styles.service__leftSide}>
        <h2 className={styles.service__leftSide__title}>What Does Truck Dispatching Include</h2>
        <p className={styles.service__leftSide__text}>
          Forget about everything that makes you feel uncomfortable in 
          truck dispatching and dealing with an annoying trucking environment.
          </p>
      </div>

      <div className={styles.service__rightSide}>
        <ul className={styles.service__rightSide__list}>
        <li className={styles.service__rightSide__item}> <span>01</span>Personal Touch </li>
        <li className={styles.service__rightSide__item}> <span>02</span>Brokers Background  </li>
        <li className={styles.service__rightSide__item}> <span>03</span>Company Development  </li>
        <li className={styles.service__rightSide__item}> <span>04</span>Planning Ahead  </li>
        <li className={styles.service__rightSide__item}> <span>05</span>Handling All Paperwork  </li>
        <li className={styles.service__rightSide__item}> <span>06</span> Support 24/7   </li>
        </ul>
      </div>

    </div>
    </section>
  )
  }
