import React from "react";
import car from '../../assets/image/car.jpg';
import styles from './styles.module.scss';
const   About = () => {
  return (
    <section className="lg:py-[15px] lg:md:pb-[45px] text-black about relative">
      <div className="mx-auto xl:max-w-[1184px] lg:max-w-[950px] lg:mb-16 xl:p-8 p-4">
        <h2 className="lg:text-[50px] text-xl text-center font-normal lg:mb-7 mb-6  text-[black]">
          About <span className={styles.about__title}>Us</span>
        </h2>
        <p className="texts max-w-2xl m-auto lg:text-2xl text-sm text-center mb-7">
          <span className={styles.about__name}>NTG </span> Logistics is a friendly dispatch
          company that provides you the best services for your business. We have
          a team of experienced <span className={styles.about__name}>dispatcher's </span>
          that will make your job much easier and profitable. Don't lose your
          chance to move only best payed loads and work with the best team
          ever!!
        </p>
      </div>
      <img className="opacity-[0.09] absolute top-0 bottom-0 w-[650px]" src={car} alt="" />
    </section>
  );
};

export default About;
