
import logo from "../../../assets/image/NTG-logo-removebg.png";
import styles from './styles.module.scss';

export const Footer = () => {

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__wrap}>
      <div className={styles.footer__rightSide}>
        <div className={styles.footer__logo}>
        <img src={logo} alt='logo' width={100} height={100} />
        </div>
        <h3>
        THE #1 DISPATCH SERVICES
        </h3>
        <div style={{color:'white'}}> <p>Copyright 2018 - 2024. &nbsp;</p> <p>NTG Agency Inc. All Rights Reserved.</p> </div>
      </div>
      <div className={styles.footer__leftSide}>

      </div>

      </div>
    </footer>
  );
};
